import React from "react";
import './HowTo.css';
import Step1 from '../../images/Step1.svg';
import Step2 from '../../images/Step2.svg';
import Step3 from '../../images/Step3.svg';
import Step4 from '../../images/Step4.svg';
import Step5 from '../../images/Step5.svg';



const HowTo = () => (
  <div className="how-to-BG">
    <div className="centered-title-wrapper">
      <h1> How it Works </h1>
    </div>

    <div className="steps">
      <img src={Step1} className="step" alt="Step 1: Set a Goal" />
      <img src={Step2} className="step" alt="Step 2: Pick a Friend" />
      <img src={Step3} className="step" alt="Step 3: Put up Stakes" />
      <img src={Step4} className="step" alt="Step 4: Commit & Do your Best" />
      <img src={Step5} className="step" alt="Step 5: Succeed or Pay Up" />
    </div>


  </div>
);


export default HowTo;
