import React from "react";
import './BottomForm.css';

const BottomForm = () => (
  <div className="bottom-form-wrapper">
    <div class="bottom-form-title">
      <h1>Accountabull</h1>
    </div>

    <p>
      Want your bull held to account?
    </p>

    <div className="mailing-list-form">
    <form action="https://spotlightly.us10.list-manage.com/subscribe/post?u=eb456326b09a88e6f46f5092c&amp;id=c7555a79d7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div className="mailing-list-form">
          <input type="email"  name="EMAIL"  className="input" placeholder="Enter your email" />
          <input type="submit" value="Join the mailing list" className="form-button" />
        </div>
        </form>
    </div>
  </div>

);

export default BottomForm;
