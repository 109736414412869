import React from "react";
import Mockup from '../../images/iPhone-Mockup.svg';
import './Hero.css';

const Hero = () => (
  <div className="hero-section">

    <div className="left-side">

      <div className="left-text-wrapper">
        <h1>Accountabull</h1>
        <p className="subtitle">
        Hold your friends
        </p>

        <p className="sign-up-now">
          Sign up now and get notified when we launch
        </p>

    <form action="https://spotlightly.us10.list-manage.com/subscribe/post?u=eb456326b09a88e6f46f5092c&amp;id=c7555a79d7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div className="mailing-list-form">
          <input type="email"  name="EMAIL"  className="input" placeholder="Enter your email" />
          <input type="submit" value="Join the mailing list" className="form-button" />
        </div>
        </form>
      </div>
    </div>

    <div className="right-side">
      <img src={Mockup} className="mockup" alt="logo" />
    </div>
  </div>
);

export default Hero;
