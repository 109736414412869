import React from 'react';
import Footer from '../sections/Footer';

export const Terms = () => (
  <div className="terms">
    <main className='main-area'>
      <h2>Terms of Use</h2>
      <article className='main-content'>

        <h4>1. The Terms</h4>

        <p>1.1. These Terms of Service (the “Terms”) are a binding agreement between you, an individual user (“You” or “Your”), and Spotlightly Inc. (“Spotlightly”), in relation to all games and applications made available by Spotlightly (jointly and interchangeably referred to as the “Services”), and any information, text, graphics, video, sound, pictures, and any other materials appearing, sent, uploaded, communicated, transmitted or otherwise made available via the above mentioned Services (jointly referred to as the “Content”).</p>

        <p>1.2. You agree to be bound by the Spotlightly Privacy Policy.</p>

        <p>1.3. You are affirming that You are of legal age to access the Services and Content, or have the consent of Your legal guardian.</p>
        <h4>2. The Services</h4>
        <p>2.1. Spotlightly gives You, so long as You are in compliance with these Terms, a personal, royalty-free, non-assignable, non-exclusive and revocable license to use the software that is provided as part of the Services. </p>

        <p>2.2. The Services may include advertisements, which may be targeted to the Content or information on the Services, queries made through the Services, or any other information. </p>
        <h4>3. Content</h4>
        <p>3.1. You are responsible for Your use of the Services and for any Content that You post, upload or submit to the Services, and for any consequences thereof. Content submitted by You may be viewed by other users and through third party services and/or websites.</p>

        <p>3.2. All Content, whether publicly posted or privately transmitted, is the sole responsibility of the User who originated such Content. Spotlightly does not monitor or control any Content posted by You via the Services, and therefore does not take responsibility for such Content and does not guarantee the accuracy, integrity or quality of that Content. Any use or reliance on any Content or materials posted or obtained by You through the Services is at their own risk. </p>

        <p>3.3 You also acknowledge that, by using the Services, You may be exposed to Content that may be considered offensive, inaccurate, indecent, objectionable or otherwise inappropriate. </p>

        <p>3.4 Under no circumstances will Spotlightly be liable in any way for any such Content as explicitly stated above or otherwise, including, but not limited to, any errors or omissions in any Content, or any losses or harm of any kind resulting from the use of any Content posted, transmitted or otherwise made available via the Services. </p>

        <p>3.5 Spotlightly may remove uploaded Content at its own discretion, including but not limited to when such Content results in a breach of these Terms. </p>

        <p>3.6. You agree not to upload, communicate, transmit or otherwise make available any Content that is or could reasonably be viewed as unlawful, or pornographic in nature, or invasive of another's privacy, likely to incite violence or racial or ethnic hatred, which infringes any intellectual property right or other proprietary right, which consists of any unsolicited or unauthorized advertising, or which contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.</p>

        <p>3.7. You shall not use the Service to harm anyone or to cause offense to or harass any person, use it for fraudulent or abusive purposes, interfere with or disrupt the Services or the servers or networks that provide it, attempt to decompile, reverse engineer, disassemble or hack the Services or any of its features, or collect any information about or regarding other users, including but not limited to any personal data or information, use the Services in violation of any applicable law or regulation, or use the Services in any other way not permitted by these Terms.</p>
        <h4>4. Users accounts</h4>
        <p>4.1. If You make use of a password login then You are responsible for safeguarding the password that they use to access the Services. Spotlightly encourages You to use strong passwords (passwords that use a combination of upper and lower case letters, numbers and symbols). Spotlightly shall not be liable for any loss or damage arising from User’s failure to comply with the above.</p>

        <p>4.2. You agree to take all steps necessary to protect login details and keep them secret, and not give these account details to anyone else or allow anyone else to use their login details or account, including login details and account for any social network or platform that You may allow the Services to interact with. Spotlightly shall not have any responsibility for the consequences of failure by You to abide by these provisions, and You agree to fully compensate Spotlightly for any losses or harm that Spotlightly might incur as a result of the failure of the User to abide by these provisions. </p>

        <p>Spotlightly shall not be responsible for any loss that You may suffer as a result of an unauthorized access to Your account(s) and/or use of the Services, and Spotlightly accepts no responsibility for any losses or harm resulting from this unauthorized use, whether fraudulently or otherwise.</p>
        <h4>5. Privacy Policy</h4>
        <p>5.1. Any information that You provide to Spotlightly is subject to the Privacy Policy, which governs the collection and use of the information. You understand that through Your use of the Services, You consent to the collection, use, and transfer of such information, as set forth in the Privacy Policy, which forms an integral part of these Terms.</p>

        <p>5.2. Spotlightly will only collect, process, use and share Your information in accordance with the Privacy Policy. Any personal information that may be collected may also be subject to the policies of any online social network that You may use to sign in and/or link with the Services.</p>

        <p>5.3. You acknowledge and accept that You may receive occasional communications, such as service announcements and administrative messages from Spotlightly.</p>
        <h4>6. Intellectual Property</h4>
        <p>6.1. You acknowledge that all copyright, trademarks, and other intellectual property rights in and relating to the Services, is owned by (or licensed to) Spotlightly. By submitting, posting, or displaying Content on or through the Services, You grant Spotlightly a worldwide, non-exclusive, royalty-free license, with the right to sub-license, to use, copy, reproduce, process, adapt, modify, publish, transmit, display, distribute and make derivative works of such Content in any and all media or distribution methods now known or later developed.</p>

        <p>6.2. You agree that the above license includes the right for Spotlightly to provide, promote, and improve the Services and to make Content submitted to or through the Services available to other companies, organizations or individuals who partner with Spotlightly for the syndication, broadcast, distribution or publication of such Content on other media and services, subject to these Terms. Additional uses by Spotlightly, or other companies, organizations or individuals who partner with Spotlightly, may be made with no compensation paid to You with respect to the Content that is submitted, posted, transmitted or otherwise made available through the Services by You.</p>

        <p>6.3. You acknowledge and accept that Content may be modified or adapted in order to transmit, display or distribute it to the Services and/or make changes to such Content as necessary to conform and adapt that Content to any requirements or limitations of any Services, devices, third party services or media.</p>

        <p>6.4. You are fully responsible for Your use of the Services, for any Content provided, and for any consequences thereof. Your Content may be syndicated, broadcasted, distributed, or published by third parties and if such </p>

        <p>6.5. By submitting Content You represent that You are fully entitled to do so, and to grant Spotlightly the right to edit, adapt, publish and use such Content and create derivative works from it, in any and all media, for any purpose, in perpetuity, without any payment. You agree to waive any rights that You may have in the Content, and agree that Spotlightly has no obligation to monitor or protect such rights in any Content that You may submit.</p>

        <p>6.6. Spotlightly reserves the right to remove Content alleged to be infringing copyright laws without prior notice, at its sole discretion, and without any liability. Spotlightly may also terminate Your account if You are determined to be a repeat infringer.</p>
        <h4>7. Breach </h4>
        <p>7.1. Spotlightly reserves the right at all times to remove or refuse to include, distribute and/or display any Content on the Services, to suspend or terminate Your account, and to reclaim usernames and/or any channel name without any liability. Spotlightly also reserves the right to access, read, preserve, and disclose any information as it reasonably believes is necessary to satisfy any applicable law, regulation, legal process or governmental request, enforce the Terms, including investigation of potential violations hereof, detect, prevent, or otherwise address fraud, security or technical issues, or to protect its rights, property or safety, its users and third parties.</p>

        <p>7.2. You shall not do any of the following while accessing or using the Services: access, tamper with, or use non-public areas of the Services, Spotlightly’s computer systems, or the technical delivery systems of Spotlightly’s providers; probe, scan, crack, track and/or test the vulnerability of any system or network, or breach or circumvent any security or authentication measures; access or search or attempt to access or search the Services by any means (automated or otherwise) other than through the currently available, published interfaces provided and only pursuant to these Terms, forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or interfere with, or disrupt, (or attempt to do so), the access of any User, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services. </p>

        <p>7.3. Spotlightly reserves the right to suspend or terminate any access to the Services, including by terminating or deleting any accounts and any Content related, if there is a reasonable belief that You are in breach of these Terms. </p>

        <p>7.4. If You cause a breach then You shall compensate Spotlightly for all losses, harm, claims and expenses.</p>
        <h4>8. Disclaimers</h4>
        <p>8.1. All rights, title, and interest in and to the Services are and will remain the exclusive property of Spotlightly and its licensors. Nothing in these Terms gives You any right to use the Spotlightly name or any of the Spotlightly trademarks, logos, domain names, properties and other distinctive brand features.</p>

        <p>8.2. These Terms will continue to apply until terminated by either You or Spotlightly as follows: (i) You may end this legal agreement with Spotlightly at any time for any reason, by discontinuing the use of the Services and contacting mike@spotlightly.com to delete Your account, provided that You will still be responsible for all the conditions set in this Term for the time they used the Services. Accounts may be deactivated and/or deleted due to prolonged inactivity; (ii) Spotlightly may suspend or terminate Your accounts or cease providing with all or part of the Services at any time for any reason, including, but not limited to, if there is a reasonable belief that such Your have violated these Terms or created a risk or possible legal exposure; or if at its own discretion the provision of the Services is no longer commercially and or technically viable.</p>

        <p>8.3. Each of the subsections below only applies up to the maximum extent permitted under applicable law. Nothing in this section is intended to limit any rights You may have which may not be lawfully limited.</p>

        <p>8.4. The access to and use of the Services or any Content are at Your own risk. You understand and agree that the Services are provided on an "as is" and "as available" basis. Without limiting the foregoing, to the maximum extent permitted under applicable law, Spotlightly disclaims all warranties and conditions, whether express or implied, of merchantability, fitness for a particular purpose or non-infringement.</p>

        <p>8.5. Spotlightly makes no warranty and disclaims all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or reliability of the Services or any Content; (ii) any harm to Your computer system or mobile device operating system, loss of data, or other harm that results from access to or use of the Services or any Content; (iii) the deletion of, or the failure to store or to transmit, any Content and other communications maintained by the Services; and (iv) whether the Services will meet Your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained from Spotlightly or through the Services, will create any warranty not expressly made herein.</p>


        <p>8.6. The Services may contain links to third-party websites or resources. You acknowledge and agree that Spotlightly is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Spotlightly of such websites or resources or the content, products, or services available from such websites or resources, unless explicitly stated otherwise. You acknowledge sole responsibility for and assume all risk arising from its use of any such websites or resources.</p>

        <p>8.7. To the maximum extent permitted by applicable law, Spotlightly shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly or any loss of data, use, good-will, or other intangible losses, resulting from (i) the access to, use of, or inability to access to or use the services; (ii) any conduct or content of any third party on the Services, including without limitation, any defamatory, offensive or illegal conduct of other users or third parties; (iii) any Content obtained from the Services; or (iv) unauthorized access, use or alteration of Content. </p>

        <p>8.8. You acknowledge that Your only right with respect to any problems or dissatisfaction with the Services is to discontinue its use and/or delete Your account(s), since Spotlightly is not responsible or liable for any interruptions, errors or discontent that You may experience while accessing the Services.</p>

        <p>8.9. The failure of Spotlightly to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.</p>

        <p>8.10. These terms shall be governed by and construed in accordance with the laws of Ontario, Canada. All claims, legal proceedings or litigation arising in connection with the Services will be brought solely in the relevant courts located in the City of Toronto, Ontario, Canada. You consent to the jurisdiction of and venue in such courts and waive any objection as to forum non conveniens.</p>

        <p>8.11. These Terms, which include the Privacy Policy, are the entire and exclusive agreement between Spotlightly and You regarding the Services, excluding any services for which You may have a separate agreement explicitly in addition or in place of these Terms, and these Terms supersede and replace any prior agreements between Spotlightly and You regarding the Services.</p>

        <p>8.12. Spotlightly reserves the right to amend this agreement by providing you with 30 days notice (“Amended Terms”). You are not permitted to amend this agreement. </p>

        <p>However, by continuing to access or use the Services after any Amended Terms become effective, You agree to be bound by the Amended Terms. </p>

        <p>8.13. Spotlightly may assign this agreement without notice as part of a sale of all or substantially all of its assets. You are not permitted to assign this agreement. </p>

        <p>8.14. These Terms were last updated on April 20, 2020.</p>


      </article>
    </main>
    <Footer/>
  </div>

);