import React from "react";
import "./Footer.css";
import FooterTop from "../../images/Footer-top.svg";
import Instagram from "../../images/Instagram.svg";
import Tiktok from "../../images/Tiktok.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear().toString();
  return (
    <footer>
      <img src={FooterTop} className="footer-top" alt="Top of the footer" />
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-links">
            <p>
              Accountabull&trade; is a trademark of{" "}
              <a href="https://www.spotlightly.com" class="twitter-mike">
                Spotlightly Inc
              </a>{" "}
              &copy; {currentYear}. All rights reserved.
            </p>
          </div>

        <div class='right-footer'>
          <a
            className="social-media-wrapper"
            href="https://www.instagram.com/accountabull"
          >
            <img
              src={Instagram}
              className="social-media-link instagram-icon"
              alt="Instagram Icon"
            />
          </a>
          <br />
          <a
            className="social-media-wrapper"
            href="https://www.tiktok.com/@accountabull"
          >
            <img
              src={Tiktok}
              className="social-media-link instagram-icon"
              alt="Instagram Icon"
            />
          </a>
          <a
            className="social-media-wrapper"
            href="/terms"
          >
            <p>Terms of use</p>
          </a>
          <a
            className="social-media-wrapper"
            href="/privacy"
          >
            <p>Privacy Policy</p>
          </a>
        </div>
        </div>
        <div className="far-away" aria-hidden="true">
          <input
            type="text"
            name="b_eb456326b09a88e6f46f5092c_c7555a79d7"
            tabindex="-1"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
