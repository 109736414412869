import React from 'react';
import Hero from "./sections/Hero";
import HowTo from "./sections/HowTo";
import BottomForm from "./sections/BottomForm";
import Footer from "./sections/Footer";
import './App.css';

import HowToTop from './images/BG-howto-top.svg';
import StarsBG from './images/Stars-BG.svg';
import Moon from './images/Moon.svg';

const App = () => (
  <main>
    <div className="Nav-and-Hero-wrapper">
      <span className="sun" />
      <Hero />
    </div>

    <div className="how-to-wrapper">
      <img src={HowToTop} className="how-to-top" alt="Top of the How To section" />
      <HowTo />
      <img src={StarsBG} className="stars-BG" alt="Stars" />

      <div className="moon-wrapper">
        <img src={Moon} className="moon" alt="Moon" />
      </div>
    </div>

    <BottomForm />

    <Footer />
  </main>
);

export default App;
